<template>
  <div class="app-container">
    <div class="content_data">
      <div
        style="display: flex; align-items: center;width:98%;margin: 0 auto;height:70px"
      >
        <div style="width:8%">统计</div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-gongsi1"></i>
          </div>
          <div style="margin-left:5px;cursor: pointer;" @click="getType(0)">
            <div style="margin-bottom:5px">企业总数</div>
            <a>
              <span>{{ sum.sum }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-zichan"></i>
          </div>
          <div style="margin-left:5px;cursor: pointer;" @click="getType(1)">
            <div style="margin-bottom:5px">有进项</div>
            <a>
              <span>{{ sum.inInvoice }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-jiezhang"></i>
          </div>
          <div style="margin-left:5px;cursor: pointer;" @click="getType(2)">
            <div style="margin-bottom:5px">有销项</div>
            <a>
              <span>{{ sum.outInvoice }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-jizhanghesuan"></i>
          </div>
          <div style="margin-left:5px;cursor: pointer;" @click="getType(3)">
            <div style="margin-bottom:5px">有银行</div>
            <a>
              <span>{{ sum.bank }}</span
              >家
            </a>
          </div>
        </div>
        <div class="each">
          <div class="icon_bg">
            <i class="iconfont icon-jizhanghesuan"></i>
          </div>
          <div style="margin-left:5px;cursor: pointer;" @click="getType(4)">
            <div style="margin-bottom:5px">有凭证</div>
            <a>
              <span>{{ sum.voucher }}</span
              >家
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="content_select">
      <div>
        账期：
        <qzf-search-period
          v-model:period="listQuery.beginPeriod"
          @success="getList"
        ></qzf-search-period>
        <span style="margin:0 3px">至</span>
        <qzf-search-period
          v-model:period="listQuery.endPeriod"
          @success="getList"
        ></qzf-search-period>
        <el-input
          placeholder="请输入企业名称/编号"
          v-model="listQuery.name"
          style="width: 160px;margin: 0 5px"
          size="small"
          @keyup.enter="getList"
          clearable
        /><el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px;"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 15px;width:350px" class="styleForm" label-width="100px" size="small">
            <el-form-item label="纳税人类型：">   
              <selecttaxtype v-model:type="listQuery.comType" style="width:209px;margin: 0"></selecttaxtype>
            </el-form-item>
            <el-form-item label="税局：">   
              <selectcity v-model:citys="listQuery.districtCode" style="width: 209px;margin: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:209px;margin: 0;"></selectuser>
            </el-form-item>
            <el-form-item label="公司类型："> 
              <el-select v-model="listQuery.companyType" size="small" placeholder="请选择公司类型"  filterable clearable style="width:209px;margin: 0;">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="进项清单量：">
              <el-input v-model="listQuery.inInvoiceStart" placeholder="进项清单量起" style="width:100px" @change="(e)=>{listQuery.inInvoiceStart= e? Number(e):null}"></el-input>
              <span style="margin: 0 2px;">-</span>
              <el-input v-model="listQuery.inInvoiceEnd" placeholder="进项清单量止" style="width:100px" @change="(e)=>{listQuery.inInvoiceEnd= e?Number(e):null}"></el-input>
            </el-form-item>
            <el-form-item label="销项清单量：">
              <el-input v-model="listQuery.outInvoiceStart" placeholder="销项清单量起" style="width:100px" @change="(e)=>{listQuery.outInvoiceStart= e?Number(e):null}"></el-input>
              <span style="margin: 0 2px;">-</span>
              <el-input v-model="listQuery.outInvoiceEnd" placeholder="销项清单量止" style="width:100px" @change="(e)=>{listQuery.outInvoiceEnd= e?Number(e):null}"></el-input>
            </el-form-item>
            <el-form-item label="银行流水量：">
              <el-input v-model="listQuery.bankStart" placeholder="银行流水量起" style="width:100px" @change="(e)=>{listQuery.bankStart= e?Number(e):null}"></el-input>
              <span style="margin: 0 2px;">-</span>
              <el-input v-model="listQuery.bankEnd" placeholder="银行流水量止" style="width:100px" @change="(e)=>{listQuery.bankEnd= e?Number(e):null}"></el-input>
            </el-form-item>
            <el-form-item label="凭证量：">
              <el-input v-model="listQuery.voucherStart" placeholder="凭证量起" style="width:100px" @change="(e)=>{listQuery.voucherStart= e?Number(e):null}"></el-input>
              <span style="margin: 0 2px;">-</span>
              <el-input v-model="listQuery.voucherEnd" placeholder="凭证量止" style="width:100px" @change="(e)=>{listQuery.voucherEnd= e?Number(e):null}"></el-input>
            </el-form-item>
          </el-form>
        </search>
        <span style="margin-left:5px"
          ><el-switch
            v-model="listQuery.statType"
            active-text="只显示有数据项"
            inactive-text=""
            @change="getList"
            size="small"
          ></el-switch
        ></span>
      </div>
      <div>
        <el-button @click="daochu()" type="primary" size="small" :disabled="!$buttonStatus('djtj_dc')">
          <el-icon><FolderOpened /></el-icon> <span>导出</span>
        </el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="list"
        border
        style="width: 100%"
        v-loading="loading"
        :height="contentStyleObj"
        @select="handleSelectionChange"
        stripe
        @sort-change="sortChange"
        ref="tableScroll" 
        id="tableLazyLoad" 
        @select-all="handleSelectionChangeAll"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" prop="id" type="selection" width="55" />
        <el-table-column label="编号" align="center" width="80" fixed="left" prop="sort" sortable="custom">
          <template #default="scope">
            <TableSortCell :row="scope.row" idKey="comId" />
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="企业名称"
          min-width="240"
          show-overflow-tooltip
          fixed
        >
          <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showAuthIcon="false"></TagNameCopy>
          </template>
        </el-table-column>
        <el-table-column
          prop="districtName"
          label="税局"
          width="65"
          align="center"
        >
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="inInvoice"
          label="进项清单量"
          align="right"
          min-width="100"
          sortable
        />
        <el-table-column
          prop="outInvoice"
          label="销项清单量"
          align="right"
          min-width="100"
          sortable
        />
        <el-table-column
          prop="bank"
          label="银行流水量"
          sortable
          align="right"
          min-width="100"
        />
        <el-table-column
          prop="voucher"
          label="凭证量"
          sortable
          align="right"
          min-width="100"
        />
        <el-table-column prop="cnName" label="会计人员" align="right">
          <template #default="scope" >
            <div v-if="scope.row.cnName">
              <DynamicPopover >
                <template #reference>
                  <div @click="poper(scope.row.comId)" style="width: 90%;cursor:pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    {{ scope.row.cnName.toString()  }}
                  </div>
                </template>
                <KjTable :list="gridData" />
             </DynamicPopover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
        type="billStatistics"
      />
    </div>
  </div>
</template>

<script>
import { djtj } from "@/api/export";
import { currentAccountPeriod } from "@/utils";
import { getCompanyDjtjList,companyUserList } from "@/api/company";
import selectcity from "@/components/Screening/selectcity";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import DynamicPopover from '@/components/DynamicPopover/DynamicPopover'
import KjTable from '@/components/table-cell-render/KjTable'
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { COMPANY_TYPE_OPTION } from '@/utils/commonData.js'

export default {
  name: "billStatistics",
  components: {
    selectcity,
    selecttaxtype,
    TagNameCopy,
    TableSortCell,
    DynamicPopover,
    KjTable
  },
  props: {},
  data() {
    return {
      typeOptions: COMPANY_TYPE_OPTION,
      listQuery: {
        page: 1,
        limit: 20,
        name: "",
        beginPeriod: currentAccountPeriod(),
        endPeriod: currentAccountPeriod(),
        type: 0,
        companyType:''
      },
      loading: false,
      list: [],
      total: 0,
      selects:[],
      ids: [],
      sum: {
        bank: 0,
        inInvoice: 0,
        outInvoice: 0,
        sum: 0,
        voucher: 0,
      },
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      gridData:[],//负责人数据
    };
  },
  created() {
    this.listQuery.limit = localStorage.getItem("billStatistics")
      ? localStorage.getItem("billStatistics") * 1
      : 20;
    this.contentStyleObj = this.$getHeight(300);
  },
  mounted() {
    this.getList();
  },
  methods: {
     // 列表点击
     poper(val){
      companyUserList({comId:val}).then(res=>{
        this.gridData = res.data.data.list
      })
    },
    // 重置
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery={
        page: 1,
        limit: originLimit,
        name: "",
        beginPeriod: currentAccountPeriod(),
        endPeriod: currentAccountPeriod(),
        type: 0,
        comType:null,
        districtCode:null,
        userId:null,
        inInvoiceStart:null,
        inInvoiceEnd:null,
        outInvoiceStart:null,
        outInvoiceEnd:null,
        bankStart:null,
        bankEnd:null,
        voucherStart:null,
        voucherEnd:null,
        companyType:''
       }
      this.getList()
    },
    getList() {
      this.loading = true;
      getCompanyDjtjList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.sum = res.data.data.sum;
          this.tableData = res.data.data.list ? res.data.data.list : []
          this.total = res.data.data.total
          this.setScroll()
        }
      });
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.ids = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    getIds(){
      let ids = [];
      this.selects.map((v) => {
        ids.push(v.comId);
      });
      this.ids = ids;
    },
    // 筛选打印
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.allChecked = true
        this.getIds()
      }else{
        this.selects = []
        this.ids = []
        this.allChecked = false
      }
    },
    //筛选
    getType(flag) {
      this.listQuery.type = flag;
      this.getList();
    },
    //导出
    daochu() {
      this.listQuery.comIds = this.ids;
      djtj(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    // 表格排序
    sortChange(data) {
      if(data.column.label == '编号'){
        if(data.order == "descending"){
          this.listQuery.desc = 1
        }else{
          this.listQuery.desc = 0
        }
        this.getList()
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content_data {
  background-color: #d8ecff;
}
.each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.each span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.each a {
  text-decoration-line: underline;
  text-decoration-color: #141720;
}
.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
}
.iconfont {
  font-size: 18px;
  //color: var(--themeColor, #17a2b8);
  color: #17a2b8;
}
.content_select {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
